import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CardHeader, Input, Slider} from "@mui/material";
import {useState} from "react";
import imageName from "./background.jpeg"

const factions = [{
    name: 'Crimean Khanate', image: './crimea.png'
}, {
    name: 'Republic of Polania', image: './polania.png'
}, {
    name: 'Saxony Empire', image: './saxony.png'
}, {
    name: 'Nordic Kingdoms', image: './nordic.png'
}, {
    name: 'Rusviet Union', image: './rusviet.png'
}, {
    name: 'Clan Albion', image: './alb.png'
}, {
    name: 'Togawa Shogunate', image: './togawa.png'
}, {
    name: 'Fenris', image: './fenris.png'
}, {
    name: 'Vesna', image: './vesna.png'
}];

const darkTheme = createTheme({
    palette: {
        mode: 'dark',

    },
});

const mats = [{
    name: 'Industrial', number: '1'
}, {
    name: 'Engineering', number: '2'
}, {
    name: 'Militant', number: '2A'
}, {
    name: 'Patriotic', number: '3'
}, {
    name: 'Innovative', number: '3A'
}, {
    name: 'Mechanical', number: '4'
}, {
    name: 'Agricultural', number: '5'
},]

const bannedCombinations = [{
    faction: 'Crimean Khanate', mat: 'Patriotic'
}, {
    faction: 'Rusviet Union', mat: 'Industrial'
}

]


function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
}

function getRandomElements(elements, x) {
    // create a new array to store the randomly chosen elements
    const randomElements = [];

    // create a copy of the elements array
    const elementsCopy = [...elements];

    // loop x times
    for (let i = 0; i < x; i++) {
        // get a random index in the range 0 to the length of the elements array copy
        const randomIndex = Math.floor(Math.random() * elementsCopy.length);

        // add the element at the random index to the array of random elements
        randomElements.push(elementsCopy[randomIndex]);

        // remove the element from the elements array copy so that it cannot be chosen again
        elementsCopy.splice(randomIndex, 1);
    }

    // return the array of random elements
    return randomElements;
}



export default function Main() {


    const [players, setPlayers] = useState(2);
    const [randomFactions, setRandomFactions] = useState([]);
    const [randomMats, setRandomMats] = useState([]);
    const [playerNames,setPlayerNames] = useState([{id: '1', name: 'Player 1'},{id: '2' ,name: 'Player 2'},{id: '3' ,name: 'Player 3'},{id: '4' ,name: 'Player 4'},{id: '5' ,name: 'Player 5'},{id: '6' ,name: 'Player 6'},{id: '7' ,name: 'Player 7'}]);

    const randomize = () => {

        let ranFac = getRandomElements(factions, players);
        let ranMat = getRandomElements(mats, players);

        let illegal = false;

        for(const i in ranFac){
            if(ranFac[i].name === 'Rusviet Union' && ranMat[i].name === 'Industrial'){
                console.log('Illegal Combination Rusviet / Industrial');
                illegal = true;
                return;

            }
            if(ranFac[i].name === 'Crimean Khanate' && ranMat[i].name === 'Patriotic'){
                console.log('Illegal Combination Crimea / Patriotic');
                illegal = true;
                return;
            }
        }

        if(illegal){
            randomize()
            return;
        }else{
            setRandomMats(ranMat);
            setRandomFactions(ranFac);
        }
    }

    const handlePlayers = (value) => {
      setPlayers(value);

    }

    const handlePlayerNames = (value,index) => {
        console.log(value);
        const updatedPlayerNames = [...playerNames];
        updatedPlayerNames[index].name = value;
        setPlayerNames(updatedPlayerNames);
    }


    return (<ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <AppBar position="relative">
            <Toolbar>
                <Typography variant="h6" color="inherit" noWrap>
                    Scythe Faction Picker v0.2
                </Typography>
            </Toolbar>
        </AppBar>
        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: 'background.paper', pt: 8, pb: 6
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h2"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Select number of players and click randomize
                    </Typography>

                    <Slider
                        aria-label="Always visible"
                        defaultValue={2}
                        min={2}
                        max={7}
                        step={1}
                        marks={true}
                        onChange={(_, value) => handlePlayers(value)}
                        valueLabelDisplay="on"
                    />


                        {players >= 2 && <Input fullWidth  id={playerNames[0].id} placeholder={'Player 1' } onChange={(e) => handlePlayerNames(e.target.value,0)}></Input>}
                        {players >= 2 && <Input fullWidth  id={playerNames[1].id} placeholder={'Player 2' } onChange={(e) => handlePlayerNames(e.target.value,1)}></Input>}
                        {players >= 3 && <Input fullWidth  id={playerNames[2].id} placeholder={'Player 3' } onChange={(e) => handlePlayerNames(e.target.value,2)}></Input>}
                        {players >= 4 && <Input fullWidth  id={playerNames[2].id} placeholder={'Player 4' } onChange={(e) => handlePlayerNames(e.target.value,3)}></Input>}
                        {players >= 5 && <Input fullWidth  id={playerNames[2].id} placeholder={'Player 5' } onChange={(e) => handlePlayerNames(e.target.value,4)}></Input>}
                        {players >= 6 && <Input fullWidth  id={playerNames[2].id} placeholder={'Player 6' } onChange={(e) => handlePlayerNames(e.target.value,5)}></Input>}
                        {players >= 7 && <Input fullWidth  id={playerNames[2].id} placeholder={'Player 7' } onChange={(e) => handlePlayerNames(e.target.value,6)}></Input>}







                    <Stack
                        sx={{pt: 4}}
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                    >
                        <Button variant="contained" onClick={randomize}>Randomize</Button>

                    </Stack>
                </Container>
            </Box>
            <Container sx={{py: 2}} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={3}>
                    {randomFactions.map((card, index) => (<Grid item key={card.name} xs={12} sm={6} md={3}>
                        <Card
                            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
                        >
                            <CardHeader title={playerNames[index].name} titleTypographyProps={{align: 'center'}}>

                            </CardHeader>
                            <CardMedia
                                component="img"
                                sx={{
                                    // 16:9
                                    alignSelf: 'center',
                                    pl: '5%',
                                    pt: '5%',
                                    pr: '5%'
                                }}
                                image={card.image}

                                alt="random"
                            />
                            <CardContent sx={{flexGrow: 1}}>
                                <Typography gutterBottom variant="h6" component="h4" align={'center'}>
                                    {card.name}
                                </Typography>
                                <Typography align={'center'}>
                                    {randomMats[index].name} | {randomMats[index].number}
                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>))}
                </Grid>
            </Container>
        </main>
    </ThemeProvider>);
}